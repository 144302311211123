import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from 'service/api'
import 'styles/ClientsAssociatedList.scss';
import { useNavigate } from "react-router-dom";
import Trs from 'components/Trs/TrsComponent';
import ImageWithFallback from 'components/utils/ImageWithFallback';
import fallback from 'assets/images/profile-white.png';
import { fetchAllClients } from 'features/client/clientSlice';

const ClientsAssociatedList = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const {
        data: clientsList,
        isLoading: isClientLoading,
        isFetched,
        clientsError,
    } = useSelector((state) => state.clients);
    const csrfToken = useSelector((state) => state.user.csrfToken);
    const [inCreate, setInCreate] = useState([false]);
    const [clients, setClients] = useState([]);

    const error_when_updating_client = Trs({ jsonRef: "error_when_updating_client" });
    const error_when_loading_clients = Trs({ jsonRef: "error_when_loading_clients" });

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (clientsList.length === 0 && !isFetched) {
                    dispatch(fetchAllClients(csrfToken));
                }
                if (clientsError)
                    console.log("error while fetching clients list", clientsError);
                const clients = clientsList.map(client => ({
                    ...client,
                    email: "",
                    phone: "",
                    photo: `${api.baseUrl.slice(0, -1)}`
                }));
                setClients(clients);
                setInCreate(true);

                const firstTwoClients = clients.slice(0, 2);
                const updatedClients = await Promise.all(firstTwoClients.map(async (client) => {
                    try {
                        const clientResponse = await api.get(`notaries/get-client/${client.id}/`, csrfToken, true);
                        const updatedClientData = clientResponse.data;
                        return {
                            ...client,
                            email: updatedClientData.email,
                            phone: updatedClientData.phone,
                            photo: client.photo + updatedClientData.photo
                        };
                    } catch (error) {
                        console.error(error_when_updating_client, error);
                        return client;
                    }
                }));

                setClients(updatedClients);
            } catch (error) {
                console.error(error_when_loading_clients, error);
            }
        };

        fetchData();
    }, []);

    const goToPage = (path) => {
        navigate(path)
    }

    if (clients.length > 0 && inCreate) {
        return (
            <div className='clients-associated'>
                <h2><Trs jsonRef="clients_associated_list_your_clients" /></h2>
                <div className='clients-list'>
                    {clients.map((client) => (
                        <div className='client-card' key={client.id}>
                            <div>
                                <ImageWithFallback src={client.photo} alt='client' style={{ borderRadius: '50%', width: '120px', height: '110px' }} fallback={fallback} />
                            </div>
                            <div style={{ paddingLeft: '30px' }}>
                                <h3>{client.user_name}</h3>
                                <h3>{client.email}</h3>
                                <h3>{client.phone}</h3>
                            </div>
                        </div>
                    ))}
                </div>
                <button onClick={() => goToPage('/notary/mes-clients')}><Trs jsonRef="clients_associated_list_go_to_clients_page" /></button>
            </div>
        )
    } else if (inCreate) {
        return (
            <div className='clients-associated'>
                <h2><Trs jsonRef="clients_associated_list_no_clients" /></h2>
            </div>
        )
    }
};

export default ClientsAssociatedList;
