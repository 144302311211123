// ProfileContent.js
import Trs from "components/Trs/TrsComponent";
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import 'styles/Profile.scss';
import InputWithLabel from "components/Input/InputWithLabel";
import ButtonWithLabel from "components/Input/ButtonWithLabel";
import ButtonWithLabelNoSubmit from "components/Input/ButtonWithLabelNoSubmit";
import api from 'service/api';
import Swal from "sweetalert2";
import ImageWithFallback from 'components/utils/ImageWithFallback';
import fallback from 'assets/images/profile-blue.png';

const ProfileContent = ({ userInfos, onSaveProfile }) => {
	const csrfToken = useSelector((state) => state.user.csrfToken);
	const user = useSelector((state) => state.user.user);
	const [editMode, setEditMode] = useState(false);
	const [editPictureMode, setPictureMode] = useState(false);

	const [userPhoto, setUserPhoto] = useState({
		photo: userInfos.photo
	});

	const [formData, setFormData] = useState({
		id: userInfos.id,
		first_name: userInfos.firstName,
		last_name: userInfos.lastName,
		email: userInfos.email,
		phone: userInfos.phone,
		address: userInfos.address,
		city: userInfos.city,
		age: userInfos.age,
		photo: userInfos.photo
	});

	useEffect(() => {
		const storedPhoto = localStorage.getItem('userPhoto');
		if (storedPhoto) {
			setUserPhoto({ photo: storedPhoto });
		}
	}, []);


	useEffect(() => {
		if (editMode) {
			setFormData({
				id: userInfos.id,
				first_name: userInfos.firstName,
				last_name: userInfos.lastName,
				email: userInfos.email,
				phone: userInfos.phone,
				address: userInfos.address,
				city: userInfos.city,
				age: userInfos.age
			});
		}
	}, [editMode]);

	const handleAgeChange = (e) => {
		const newAge = e;
		setFormData({
			...formData,
			age: newAge
		});
	};

	const handleFirstNameChange = (e) => {
		const newFirstName = e;
		setFormData({
			...formData,
			first_name: newFirstName
		});
	};

	const handleLastNameChange = (e) => {
		const newLastName = e;
		setFormData({
			...formData,
			last_name: newLastName
		});
	};

	const handleEmailChange = (e) => {
		const newEmail = e;
		setFormData({
			...formData,
			email: newEmail
		});
	};

	const handlePhoneChange = (e) => {
		const newPhone = e;
		setFormData({
			...formData,
			phone: newPhone
		});
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setFormData((prevData) => ({ ...prevData, photo: file }));
		const reader = new FileReader();
		reader.onload = () => {
			setUserPhoto({
				photo: reader.result
			});
			localStorage.setItem('userPhoto', reader.result);
		};
		reader.readAsDataURL(file);
	};

	const basic_success_title = Trs({ jsonRef: "basic_success_title" });
	const information_update_success = Trs({ jsonRef: "information_update_success" });
	const error_when_updating_information = Trs({ jsonRef: "error_when_updating_information" });
	const profile_picture = Trs({ jsonRef: "profile_picture" });

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await api.put("accounts/update/", {
				'id': formData.id,
				'last_name': formData.last_name,
				'first_name': formData.first_name,
				'age': formData.age,
				'phone': formData.phone,
				'email': formData.email,
			}, csrfToken
				, true, {
				headers: {
					'Content-Type': 'multipart/form-data',
					'X-CSRFToken': csrfToken,
				},
			});

			if (response.status === 200) {
				Swal.fire({
					icon: 'success',
					title: basic_success_title,
					text: information_update_success,
				});
			} else {
				Swal.fire({
					icon: 'success',
					title: 'Error',
					text: error_when_updating_information,
				});
			}
		} catch (error) {
			console.error(error_when_updating_information, error);
		}

		setEditMode(false);
	};

	return (
		<div className="profile-infos-container ">
			<form onSubmit={handleSubmit} encType="multipart/form-data">
				<div className="profile-infos">
					<div className="profile-picture-container">
						<h2 className="title-profile-picture">{profile_picture}</h2>
						<div className="row-direction-picture">
							<div className="profile-picture">
								{!editMode ? (
									<ImageWithFallback src={userPhoto.photo} alt="Profile" fallback={fallback} />
								) : (
									<label htmlFor="profile-photo-input">
										<ImageWithFallback src={userPhoto.photo} alt="Profile" fallback={fallback} />
									</label>
								)}
								<input
									id="profile-photo-input"
									type="file"
									accept="image/*"
									style={{ display: 'none' }}
									onChange={handleFileChange}
								/>
							</div>
							<div className="profile-buttons">
								{/*

									<ButtonWithLabelNoSubmit disabled={editPictureMode ? true : false} label={<Trs jsonRef="profile_edit_profile_pic" />} onClick={() => setPictureMode(true)} />
									<ButtonWithLabel customCSS={'delete-pic-btn'} disabled={editPictureMode ? true : false} label={<Trs jsonRef="profile_delete_profile_pic" />} onClick={() => setPictureMode(true)} />
							*/}
							</div>
						</div>
					</div>
					<div className="profile-informations">
						<div className="input-next-to">
							<InputWithLabel
								name={"lastName"}
								type={'text'}
								disabled={editMode ? false : true}
								label={<Trs jsonRef="profile_items_last_name" />}
								value={formData.last_name}
								onValueChange={handleLastNameChange}
							/>
							<InputWithLabel
								type={'text'}
								name={'firstName'}
								disabled={editMode ? false : true}
								label={<Trs jsonRef="profile_items_firstname" />}
								value={formData.first_name}
								onValueChange={handleFirstNameChange}
							/>
						</div>
						<div className="input-space">
							<InputWithLabel
								type={'text'}
								name={'email'}
								disabled={editMode ? false : true}
								label={<Trs jsonRef="profile_items_email" />}
								value={formData.email}
								onValueChange={handleEmailChange}
							/>
						</div>
						<div className="input-next-to input-space">
							<InputWithLabel
								type={'number'}
								name={'age'}
								disabled={editMode ? false : true}
								label={<Trs jsonRef="profile_items_age" />}
								value={formData.age}
								onValueChange={handleAgeChange}
							/>
							<InputWithLabel
								type={'text'}
								name={'phone'}
								disabled={editMode ? false : true}
								label={<Trs jsonRef="profile_items_phone" />}
								value={formData.phone}
								onValueChange={handlePhoneChange}
							/>
						</div>
					</div>
					<br />
					<div className="profile-button">
						<ButtonWithLabelNoSubmit disabled={editMode ? true : false} label={<Trs jsonRef="profile_edit_button" />} onClick={() => setEditMode(true)} />
						<ButtonWithLabel disabled={editMode ? false : true} label={<Trs jsonRef="profile_save_button" />} onClick={handleSubmit} />
					</div>
				</div>
			</form>
		</div>
	);

};

export default ProfileContent;
