import React from 'react';

export const Step = ({ fields, data, handleChange, errors }) => (
    <div className="w-full h-auto">
        {fields.map((field, index) => (
            <div key={index} className="mb-3 px-8">
                <label className="block font-medium my-2">{field.label} :</label>
                {field.type != "textarea" &&
                    <input
                        className={`w-full rounded-lg border border-gray-300 p-2 pr-10 ${errors[field.name] ? 'border border-red border-opacity-80' : ''}`}
                        type={field.type || 'text'}
                        name={field.name}
                        value={data[field.name] || ''}
                        placeholder={field.placeholder || ''}
                        onChange={handleChange}
                    />}
                {field.type === "textarea" &&
                    <textarea rows={7} className={`w-full resize-none border-1 border-opacity-70 rounded-lg border-grey ${errors[field.name] ? ' border-red opacity-80' : ''}`} name={field.name} value={data[field.name] || ''} onChange={handleChange} />
                }
                {errors[field.name] && <p className="text-red mt-2 text-sm">{errors[field.name]}</p>}
            </div>
        ))}
    </div>
);


export const StepsDoc = ({ stepFields, activeStep, formData, handleInputChange, handleSelectChange, errors }) => {
    // Vérification si stepFields est valide
    if (!stepFields || typeof stepFields !== "object") {
        return <div className="text-gray-500">Aucun champ à afficher.</div>;
    }

   return (
        <div className="w-full h-auto">
            {Object.entries(stepFields).map(([key, value]) => {
                if (key === "SEXE") {
                    return (
                        <div key={key} className="mb-4">
                            <label className="block font-medium mb-2">{key}</label>
                            <select
                                value={formData[`${activeStep}_${key}`] || ""}
                                onChange={(e) => handleSelectChange(e, key)}
                                className={`w-full rounded-lg border ${errors[`${activeStep}_${key}`]
                                    ? "border-red-500"
                                    : "border-gray-300"
                                    } p-2`}
                            >
                                <option value="" disabled>
                                    SEXE
                                </option>
                                <option value="H">Homme</option>
                                <option value="F">Femme</option>
                            </select>
                            {errors[`${activeStep}_${key}`] && (
                                <p className="text-red-500 text-sm mt-1">{errors[`${activeStep}_${key}`]}</p>
                            )}
                        </div>
                    );
                } else {
                    return (
                        <div key={key} className="mb-4">
                            <label className="block font-medium mb-2">{key}</label>
                            <input
                                type="text"
                                className={`w-full rounded-lg border ${errors[`${activeStep}_${key}`]
                                    ? "border-red-500"
                                    : "border-gray-300"
                                    } p-2`}
                                value={formData[`${activeStep}_${key}`] || ""}
                                onChange={(e) => handleInputChange(e, key)}
                            />
                            {errors[`${activeStep}_${key}`] && (
                                <p className="text-red-500 text-sm mt-1">{errors[`${activeStep}_${key}`]}</p>
                            )}
                        </div>
                    );
                }
            })}
        </div>
    );
};

{/*fields.map((field, index) => (
    <div key={index} className="mb-3 px-8">
        <label className="block font-medium my-2">{field.label} :</label>
        <input
            className={`w-full rounded-lg border border-gray-300 p-2 pr-10 ${errors[field.name] ? 'border border-red border-opacity-80' : ''}`}
            type={field.type || 'text'}
            name={field.name}
            value={data[field.name] || ''}
            placeholder={field.placeholder || ''}
            onChange={handleChange}
        />
        {errors[field.name] && <p className="text-red mt-2 text-sm">{errors[field.name]}</p>}
    </div>
))*/}
