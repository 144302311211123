import { useState, useEffect, useRef } from "react";

const useInfiniteScroll = (fetchData, initialPage = 1, root = null) => {
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(initialPage);
    const [hasMore, setHasMore] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
        const loadItems = async () => {
            const { newItems, hasMoreItems } = await fetchData(page);
            if (newItems.length > 0) {
                setItems((prevItems) => [...prevItems, ...newItems]);
            }
            if (!hasMoreItems) {
                setHasMore(false);
            }
            setUpdating(false);
        };
        if ((hasMore || refresh) && !updating) {
            setUpdating(true);
            setRefresh(false);
            loadItems();
        }
    }, [page, refresh]);

    const forceRefresh = (needForce = true) => {
        if (needForce) {
            setItems([]);
            setHasMore(true);
            setPage(initialPage);
            setRefresh(true);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (root != null && !root.current || updating || !hasMore) return;
            const container = root.current;

            if (container.scrollTop + container.clientHeight >= container.scrollHeight - 100) {
                setPage((prevPage) => prevPage + 1);
            }
        };
        
        if (!root)
            return;
        const scrollContainer = root.current;
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, [root, updating, hasMore]);

    return { items, setItems, hasMore, forceRefresh };
};

export default useInfiniteScroll;
