import React from "react";
import { Link } from "react-router-dom";
import Trs from "components/Trs/TrsComponent";
import imgNotFound from 'assets/images/404.png';

const NotFound = () => {

	const not_found_ooops = Trs({ jsonRef: "not_found_ooops" });

    return (
        <div className="h-screen w-screen">
		    <div className="h-full w-full flex justify-center items-center">
				<div className="flex flex-col md:flex-col sm:flex-col lg:flex-row justify-center">
					<div className="w-full flex flex-col justify-center space-y-2 p-8">
						<h1 className="text-7xl font-semibold">{not_found_ooops}</h1>
			    		<h1 className="text-3xl"><Trs jsonRef="not_found_subtitle" /></h1>
			    		<p className="text-lg"><Trs jsonRef="not_found_message" /></p>
			    		<Link className="inline-block text-primary font-semibold " to={"/"}>
							<Trs jsonRef="not_found_back_to_home" />
						</Link>
					</div>
					<div className="w-full h-full">
						<img src={imgNotFound}/>
					</div>
				</div>
		    </div>
	    </div>
    );
}

export default NotFound;
