import React, { useEffect } from 'react'
import 'styles/Home.scss'
import { useIntersectionObserver } from 'components/utils/UseIntersectionObserver';
import homeVideo from 'assets/images/home-video.mp4';
import faqImg from 'assets/images/faq-img.png';
import notarioLetters from 'assets/SVG/notario-letters.svg';
import opinionImg from 'assets/images/opinion-img.png';
import Trs from 'components/Trs/TrsComponent';
import { ServiceCard } from 'components/HomeComponent/ServicesCard';
import { useSelector } from 'react-redux';
import { SectionButton } from 'components/HomeComponent/ButtonSection';
import { TimelineComponent } from 'components/HomeComponent/TimelineComponent';
import { MemberCard } from 'components/HomeComponent/MemberCard';
import remiPic from 'assets/images/members-img/remi.png';
import louisPic from 'assets/images/members-img/louis.png';
import mahePic from 'assets/images/members-img/mahe.png';
import valPic from 'assets/images/members-img/val.png';
import robinPic from 'assets/images/members-img/robin.png';
import tristanPic from 'assets/images/members-img/tristan.png';
import lukasPic from 'assets/images/members-img/lukas.png';
import nicoPic from 'assets/images/members-img/nico.png';
import guiguiPic from 'assets/images/members-img/guigui.png'
import { DocumentScannerOutlined } from '@mui/icons-material';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import { HistoryEduOutlined } from '@mui/icons-material';
import { QuestionAnswerOutlined } from '@mui/icons-material';
import { TodayOutlined } from '@mui/icons-material';
import config from 'core/config';


export default function Home () {
  const language = useSelector(state => state.user.language);
  const satisfactionFormLink = config.satisfactionFormLink;
  const home_page_learn_more = Trs({ jsonRef: 'home_page_learn_more' });
  const home_page_opinion_title = Trs({ jsonRef: 'home_page_opinion_title' });
  const home_page_opinion_desc = Trs({ jsonRef: 'home_page_opinion_desc' });
  const home_page_opinion_button = Trs({ jsonRef: 'home_page_opinion_button' });

  const cardTranslator = (category) => {
    return Trs({ jsonRef: `second_home_section.${category}` })
  }

  const memberTranslator = (name) => {
    return Trs({ jsonRef: `members_card.${name}_position` })
  }

  const handleFormButtonClick = () => {
    window.open(satisfactionFormLink, "_blank");
  };

  useIntersectionObserver('.img-letters-notario', 'open', {threshold: 0.5}, true);

  return (
    <>
      <section className='first-section'>
        <div className='hidden lg:flex lg:h-full'>
          <video autoPlay loop className='home-video'>
            <source src={homeVideo} type='video/mp4' />
          </video>
        </div>
        <div className='text-overlay'>
          <div className='flex flex-col h-full justify-center px-20 lg:w-[60%]'>
           <h1 className='text-white text-4xl font-semibold lg:text-6xl'><Trs jsonRef={'first_home_section_title'}/></h1>
            <p className='text-white text-xl lg:text-[1.3rem] mt-8'><Trs jsonRef={'second_text_home_page'}/></p>
          </div>
        </div>
      </section>
      <section className='second-section'>
        <div className='services-container'>
            <h1 className='service-title'><Trs jsonRef={'second_home_section.section_title'}/></h1>
            <div className='services-list'>
              <div className='first-list'>
                <ServiceCard title={cardTranslator('card_1.title')} description={cardTranslator('card_1.description')} classSVG={DocumentScannerOutlined}  delay={'300'}/>
                <ServiceCard title={cardTranslator('card_2.title')} description={cardTranslator('card_2.description')} classSVG={DevicesOutlinedIcon}  delay={'400'}/>
                <ServiceCard title={cardTranslator('card_3.title')} description={cardTranslator('card_3.description')} classSVG={HistoryEduOutlined}  delay={'500'}/>
              </div>
              <div className='second-list'>
                <ServiceCard title={cardTranslator('card_4.title')} description={cardTranslator('card_4.description')} classSVG={QuestionAnswerOutlined}  delay={'600'}/>
                <ServiceCard title={cardTranslator('card_5.title')} description={cardTranslator('card_5.description')} classSVG={TodayOutlined}  delay={'700'}/>
              </div>
            </div>
            {/*<div className='list-cards'>
              <ServiceCard title={cardTranslator('card_1.title')} description={cardTranslator('card_1.description')} img={homeImg}/>
              <ServiceCard title={cardTranslator('card_2.title')} description={cardTranslator('card_2.description')} img={card2}/>
              <ServiceCard title={cardTranslator('card_3.title')} description={cardTranslator('card_3.description')} img={card3}/>
            </div>*/}
        </div>
      </section>
      <section className='team-section'>
        <div className='team-container'>
        <h1 className='team-title'><Trs jsonRef={"members_section_title"}/></h1>
        <p className='team-small-description'><Trs jsonRef={"members_section_description"}/></p>
          <div className='team-section-carousel'>
            <div className='first-list'>
              <MemberCard title={'Valentin EYRAUD'} description={memberTranslator('val')} delay={200} img={valPic} url={'https://www.linkedin.com/in/valentin-eyraud/'}/>
              <MemberCard title={'Robin LANDRAUD'} description={memberTranslator('robin')} delay={300} img={robinPic} url={'https://www.linkedin.com/in/robin-landraud/'}/>
              <MemberCard title={'Louis LEBLOND'} description={memberTranslator('louis')} delay={400} img={louisPic} url={'https://www.linkedin.com/in/louisleblond/'}/>
              <MemberCard title={'Mahé BEAUD'} description={memberTranslator('mahe')} delay={500} img={mahePic} url={'https://www.linkedin.com/in/mahe-beaud/'}/>
            </div>
            <div className='second-list'>
              <MemberCard title={'Tristan BEAU'} description={memberTranslator('tristan')} delay={600} img={tristanPic} url={'https://www.linkedin.com/in/tristan-beau-4b7612207/'} />
              <MemberCard title={'Lukas CHIARADIA'} description={memberTranslator('lukas')} delay={700} img={lukasPic} url={'https://www.linkedin.com/in/lukas-chiaradia/'} />
              <MemberCard title={'Nicolas LAVIGNE'} description={memberTranslator('nico')} delay={800} img={nicoPic} url={'https://www.linkedin.com/in/nicolas-lavigne/'} />
              <MemberCard title={'Rémi HUGUET'} description={memberTranslator('remi')} delay={900} img={remiPic} url={'https://www.linkedin.com/in/r%C3%A9mi-huguet-346184208/'} />
              <MemberCard title={'Guillaume BOGARD COQUARD'} description={memberTranslator('guigui')} delay={1000} img={guiguiPic} url={'https://www.linkedin.com/in/guillaume-bogard-coquard/'} />
            </div>
          </div>
        </div>
      </section>
      <section className='timeline-section'>
        <h1 className='timeline-title'><Trs jsonRef={'timeline_title'}/></h1>
        <p className='timeline-description'><Trs jsonRef={'timeline_description'}/></p>
        <TimelineComponent></TimelineComponent>
      </section>
      <section className='third-section'>
      <div className='section-faq-container'>
            <div className='faq-left-container'>
              <h1 className='home-faq-title'><Trs jsonRef={'third_section_faq_title'}/></h1>
              <p className='home-faq-p'><Trs jsonRef={'third_section_faq_desc'}/></p>
              <div className='home-faq-button'>
                <SectionButton
                  goToPage={'/faq'}
                  text={home_page_learn_more}
                  secondStyle={true}
                />
              </div>
            </div>
            <div className='faq-right-container'>
              <img className='faq-img' src={faqImg} />
            </div>
          </div>
          <div className='section-about-us-container'>
            <div className='left-container'>
              <img className='img-letters-notario' src={notarioLetters} />
            </div>
            <div className='right-container'>
              <h1 className='about-us-title'><Trs jsonRef={'navbar_about_us'}/></h1>
              <p className='about-us-p'><Trs jsonRef={'third_section_about_us_desc'}/></p>
              <div className='about-us-button'>
                <SectionButton
                  goToPage={'about-us'}
                  text={home_page_learn_more}
                  secondStyle={false}
                />
              </div>
            </div>
          </div>
      </section>
      <section className='fourth-section'>
        <div className='section-opinion-container'>
          <div className='opinion-left-container'>
            <h1 className='opinion-title'>{home_page_opinion_title}</h1>
            <p className='opinion-p'>{home_page_opinion_desc}</p>
            <div className='opinion-button'>
              <div className='custom-section-button-container'>
                <button className='custom-home-btn-button-style1' onClick={handleFormButtonClick}>{home_page_opinion_button}</button>
              </div>
            </div>
          </div>
          <div className='opinion-right-container'>
            <img className='opinion-img' src={opinionImg} />
          </div>
        </div>
      </section>
    </>
  )
}
