import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "service/api";

export const fetchAllClients = createAsyncThunk(
    "clients/fetchAll",
    async (csrfToken, { rejectWithValue }) => {
        try {
            const response = await api.get(
                "notaries/get-clients/",
                csrfToken,
                true
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || "An error occurred");
        }
    }
);

const clientsSlice = createSlice({
    name: "clients",
    initialState: {
        data: [],
        isLoading: false,
        isFetched: false,
        error: null,
    },
    reducers: {
        clearClients: (state) => {
            state.data = [];
            state.isFetched = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllClients.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAllClients.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isFetched = true;
                if (
                    JSON.stringify(state.data) !==
                    JSON.stringify(action.payload)
                ) {
                    state.data = action.payload;
                }
            })
            .addCase(fetchAllClients.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export const { clearClients } = clientsSlice.actions;
export default clientsSlice.reducer;
