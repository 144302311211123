
export const clientLinks = [
  { path: "/client/mes-rendez-vous", jsonRef: "navbar_my_appointments" },
  { path: "/client/mes-documents", jsonRef: "navbar_my_documents" },
  { path: "/client/demander-un-document", jsonRef: "navbar_ask_new_document" },
  { path: "/client/chat", jsonRef: "navbar_chat" }
];

export const notaryLinks = [
  { path: "/notary/mes-rendez-vous", jsonRef: "navbar_my_appointments" },
  { path: "/notary/mes-clients", jsonRef: "navbar_my_clients" },
  { path: "/notary/draft", jsonRef: "navbar_draft" },
  { path: "/notary/office/home", jsonRef: "navbar_my_office" },
  { path: "/notary/chat", jsonRef: "navbar_chat" }
];

export const generalLinks = [
  { path: "/about-us", jsonRef: "navbar_about_us" },
  { path: "/plans", jsonRef: "Plans" },
  { path: "/articles", jsonRef: "navbar_articles" },
  { path: "/faq", jsonRef: "navbar_faq" }
];


/* TalwindClass */
let navLinkhover = "hover:text-white hover:bg-[rgba(255,255,255,0.25)] "
let navLinkBase = "text-grey hover:text-white aria-[current=page]:text-white text-base font-medium leading-6 p-2 rounded-lg";
export const navLinkClasses = navLinkBase + " " + navLinkhover;
export const mobileNavLinkClasses = "hover:bg-menuHoverColor -mx-3 block rounded-lg px-3 py-2 text-base font-medium leading-7";
