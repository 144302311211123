import React, { useState, useEffect } from "react";
import api from "service/api";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Trs from "components/Trs/TrsComponent";
import ConfirmationOnLeave from "components/utils/ConfirmationOnLeave";
import { BigSpinner } from "components/Loader/Spinner";
import { FormDocStepsProgress } from "components/utils/StepsProgress";
import { generateStepsForTemplate, RenderFormsContent, getStepData } from "./FormsUtils";
import { HandleFormDocsSubmit } from "./FormsSubmit";

export const FormsProcurationView = () => {
    const csrfToken = useSelector((state) => state.user.csrfToken);
    const [formContent, setFormContent] = useState(null);
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [activeStep, setActiveStep] = useState(1);
    const [steps, setSteps] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const basic_error = Trs({ jsonRef: "basic_error" });
    const basic_success_title = Trs({ jsonRef: "basic_success_title" });
    const form_error_text = Trs({ jsonRef: "form_error_text" });
    const form_success_text = Trs({ jsonRef: "form_success_text" });

    const client_ask_new_doc_demand_from = Trs({
        jsonRef: "client_ask_new_doc_demand_from",
    });
    const basic_previous = Trs({ jsonRef: "basic_previous" });
    const basic_next = Trs({ jsonRef: "basic_next" });
    const basic_submit = Trs({ jsonRef: "basic_submit" });
    const basic_close = Trs({ jsonRef: "basic_exit" });

    const error_when_loading_datas = Trs({
        jsonRef: "error_when_loading_datas",
    });
    const error_when_loading_template_content = Trs({
        jsonRef: "error_when_loading_template_content",
    });
    const client_ask_new_doc_description = Trs({
        jsonRef: "client_ask_new_doc_description",
    });
    const client_ask_new_doc_only_letters = Trs({
        jsonRef: "client_ask_new_doc_only_letters",
    });
    const client_ask_new_doc_date_format = Trs({
        jsonRef: "client_ask_new_doc_date_format",
    });
    const client_ask_new_doc_five_digits = Trs({
        jsonRef: "client_ask_new_doc_five_digits",
    });
    const client_ask_new_doc_ten_digits = Trs({
        jsonRef: "client_ask_new_doc_ten_digits",
    });

    const validateField = (value, fieldName) => {
        if (!value || value.trim() === "") {
            return `${fieldName} est requis`;
        }
        if (
            fieldName.toLowerCase().includes("nom") ||
            fieldName.toLowerCase().includes("prenom")
        ) {
            if (!/^[A-Za-z\s-]+$/.test(value)) {
                return `${fieldName} ${client_ask_new_doc_only_letters}`;
            }
        }
        if (fieldName.toLowerCase().includes("date")) {
            if (!/^\d{2}\/\d{2}\/\d{4}$/.test(value)) {
                return `${fieldName} ${client_ask_new_doc_date_format}`;
            }
        }
        if (fieldName.toLowerCase().includes("code_postal")) {
            if (!/^\d{5}$/.test(value)) {
                return `${fieldName} ${client_ask_new_doc_five_digits}`;
            }
        }
        if (fieldName.toLowerCase().includes("ville")) {
            if (!/^[A-Za-z\s-]+$/.test(value)) {
                return `${fieldName} ${client_ask_new_doc_only_letters}`;
            }
        }
        if (fieldName.toLowerCase().includes("numero")) {
            if (!/^\d{10}$/.test(value)) {
                return `${fieldName} ${client_ask_new_doc_ten_digits}`;
            }
        }
        return null;
    };

    useEffect(() => {
        setIsLoading(true)
        let content = null;
        let documentSteps = [];
        api.get('files/get-template/procuration/', csrfToken, true)
            .then(response => {
                content = response.data;
                documentSteps = generateStepsForTemplate(content)
                setSteps(documentSteps);
            })
            .catch(error => {
                console.error(error_when_loading_datas, error);
                content = error_when_loading_template_content
            })
            .finally(() => {
                setIsLoading(false)
                setFormContent(content);
            })
    }, []);

    const handleNextOrSubmit = () => {
        const currentStepData = getStepData(activeStep, formContent);
        const stepErrors = {};

        Object.entries(currentStepData).forEach(([key, value]) => {
            const formKey = `${activeStep}_${key}`;
            const error = validateField(formData[formKey], key);
            if (error) {
                stepErrors[formKey] = error;
            } else {
                delete errors[formKey];
            }
        });


        if (Object.keys(stepErrors).length === 0) {
            if (activeStep === (steps.length)) {
                setErrors((prevErrors) => ({ ...prevErrors, ...stepErrors }));
                handleSubmit();
                return;
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setErrors((prevErrors) => ({ ...prevErrors, ...stepErrors }));
            }
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, ...stepErrors }));
        }
    };

    const handleBack = () => {
        if (activeStep === 1) {
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleInputChange = (e, key) => {
        const { value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [`${activeStep}_${key}`]: value,
        }));
    };

    const handleSelectChange = (e, key) => {
        const { value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [`${activeStep}_${key}`]: value,
        }));
    };


    const handleClosePopup = () => {
        setActiveStep(1);
        setFormData({});
        setErrors({});
        navigate('/client/mes-documents')
    };

    const CancelForms = () => {
        navigate('/client/demander-un-document')
    }

    const handleSubmit = () => {
        let requestData = HandleFormDocsSubmit(steps, formData, formContent);
        api.post("files/fill-template/" + formContent.name + "/", requestData, csrfToken, true)
            .then(() => {
                Swal.fire({
                    icon: "success",
                    title: basic_success_title,
                    text: form_success_text,
                });
            }).catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: basic_error,
                    text: form_error_text,
                });
            }).finally(() => {
                handleClosePopup()
            })
    }

    return (
        <div className="flex flew-grow w-full">
            <div className='w-full h-screen flex items-start p-6'>
                <div className="flex w-full h-full items-center">
                    <div className="w-full h-full flex flex-col items-center space-y-5">
                        <h2 className="text-3xl w-full text-start font-medium ">{client_ask_new_doc_demand_from} {formContent?.name}</h2>
                        <p className="w-full px-3 h-[2px] bg-secondary"></p>
                        {isLoading ?
                            <>
                                <BigSpinner />
                            </> :
                            <div className="w-full h-full flex flex-col items-center pt-6 px-8 shadow-lg rounded-lg">
                                <div className="w-3/4">
                                    <FormDocStepsProgress maxSteps={steps.length} currentStep={activeStep} stepName={steps} />
                                </div>
                                <div className="w-full h-full pt-20 px-4 flex flex-col justify-start">
                                    <p className="text-base font-medium">
                                        {client_ask_new_doc_description}
                                    </p>
                                    <div className="w-full flex flex-grow justify-center items-centershadow-xl">
                                        <ConfirmationOnLeave />
                                        <div onClose={CancelForms} className="w-4/5 h-full">

                                            <div className="w-full h-full flex space-y-6 items-center">
                                                <RenderFormsContent
                                                    formContent={formContent}
                                                    activeStep={activeStep}
                                                    formData={formData}
                                                    errors={errors}
                                                    handleInputChange={handleInputChange}
                                                    handleSelectChange={handleSelectChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full h-1/6">
                                        <div className="w-full h-full flex justify-around">
                                            <button
                                                disabled={activeStep === 1}
                                                className="text-base font-medium bg-secondary rounded-lg text-white w-[10%] h-10 cursor-pointer hover:bg-primary"
                                                onClick={handleBack}
                                            >
                                                {basic_previous}
                                            </button>
                                            {activeStep === (steps.length) ? (
                                                <button
                                                    className="text-base font-medium bg-[#28a745] rounded-lg text-white w-[10%] h-10 cursor-pointer"
                                                    onClick={handleNextOrSubmit}
                                                >
                                                    {basic_submit}
                                                </button>
                                            ) : (
                                                <button
                                                    className="text-base font-medium bg-primary rounded-lg text-white w-[10%] h-10 cursor-pointer hover:bg-primary"
                                                    onClick={handleNextOrSubmit}
                                                >
                                                    {basic_next}
                                                </button>
                                            )}
                                            <button
                                                className="text-base font-medium bg-grey rounded-lg text-red w-[10%] h-10 cursor-pointer hover:bg-grey"
                                                onClick={CancelForms}
                                            >
                                                {basic_close}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};
