import React from 'react';

const ImageWithFallback = ({ src, alt, className, fallback, style }) => {
  const handleError = (e) => {
    e.target.src = fallback;
  };

  return (
    <img
      src={src}
      alt={alt}
      className={className}
      style={style}
      onError={handleError}
    />
  );
};

export default ImageWithFallback;