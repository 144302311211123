import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import 'styles/FeedbackForm.scss';
import Trs from "components/Trs/TrsComponent";

const FeedbackForm = ({ title, onClose }) => {
  const MySwal = withReactContent(Swal);

  const featureLabel = Trs({ jsonRef: "feedback_form_feature_label" }) || "Fonctionnalité :";
  const featurePlaceholder = Trs({ jsonRef: "feedback_form_feature_placeholder" }) || "Nom de la fonctionnalité";
  const commentLabel = Trs({ jsonRef: "feedback_form_comment_label" }) || "Commentaire :";
  const commentPlaceholder = Trs({ jsonRef: "feedback_form_comment_placeholder" }) || "Donnez-nous votre avis ici";
  const ratingLabel = Trs({ jsonRef: "feedback_form_rating_label" }) || "Note :";
  const ratingPlaceholder = Trs({ jsonRef: "feedback_form_rating_placeholder" }) || "Sélectionnez une note";
  const ratingOption1 = Trs({ jsonRef: "feedback_form_rating_option_1" }) || "1 - Mauvais";
  const ratingOption2 = Trs({ jsonRef: "feedback_form_rating_option_2" }) || "2 - Moyen";
  const ratingOption3 = Trs({ jsonRef: "feedback_form_rating_option_3" }) || "3 - Correct";
  const ratingOption4 = Trs({ jsonRef: "feedback_form_rating_option_4" }) || "4 - Bon";
  const ratingOption5 = Trs({ jsonRef: "feedback_form_rating_option_5" }) || "5 - Excellent";
  const submitButtonText = Trs({ jsonRef: "feedback_form_submit_button" }) || "Soumettre";
  const closeButtonText = Trs({ jsonRef: "feedback_form_close_button" }) || "Fermer";
  const successTitle = Trs({ jsonRef: "feedback_form_success_title" }) || "Merci pour votre avis !";
  const successText = Trs({ jsonRef: "feedback_form_success_text" }) || "Votre retour a bien été envoyé.";
  const errorTitle = Trs({ jsonRef: "feedback_form_error_title" }) || "Erreur !";
  const errorText = Trs({ jsonRef: "feedback_form_error_text" }) || "Une erreur est survenue, veuillez réessayer.";
  const feedbackFormTitle = Trs({ jsonRef: "feedback_form_title" }) || title;

  const FormContent = () => {
    const [featureName, setFeatureName] = useState("");
    const [comments, setComments] = useState("");
    const [rating, setRating] = useState("");

    const handleSubmit = async (e) => {
      e.preventDefault();

      const formUrl =
        "https://docs.google.com/forms/d/e/1FAIpQLSfA8Lj_1kA4GYgTqLlis2YFfwbTn5opn6bYGAw6BSnLgqqGAg/formResponse";

      const formData = new URLSearchParams();
      formData.append("entry.827827362", featureName); // title
      formData.append("entry.1462682169", comments); // comment
      formData.append("entry.13623487", rating); // rank

      try {
        await fetch(formUrl, {
          method: "POST",
          body: formData,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });

        await MySwal.fire({
          title: successTitle,
          text: successText,
          icon: "success",
          confirmButtonText: closeButtonText,
        });

        MySwal.close();
        onClose();
      } catch (error) {
        if (
          error.name === "TypeError" &&
          error.message.includes("Failed to fetch")
        ) {
          await MySwal.fire({
            title: successTitle,
            text: successText,
            icon: "success",
            confirmButtonText: closeButtonText,
          });

          MySwal.close();
          onClose();
        } else {
          console.error("Erreur lors de l'envoi du feedback :", error);
          await MySwal.fire({
            title: errorTitle,
            text: errorText,
            icon: "error",
            confirmButtonText: closeButtonText,
          });
        }
      }
    };

    return (
      <div className="feedback-form-container">
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              {featureLabel}
              <input
                type="text"
                value={featureName}
                onChange={(e) => setFeatureName(e.target.value)}
                placeholder={featurePlaceholder}
                className="feedback-input"
                required
              />
            </label>
          </div>
          <div>
            <label>
              {commentLabel}
              <textarea
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                placeholder={commentPlaceholder}
                rows="4"
                className="feedback-input"
                required
              ></textarea>
            </label>
          </div>
          <div>
            <label>
              {ratingLabel}
              <select
                value={rating}
                onChange={(e) => setRating(e.target.value)}
                className="feedback-input"
                required
              >
                <option value="">{ratingPlaceholder}</option>
                <option value="1">{ratingOption1}</option>
                <option value="2">{ratingOption2}</option>
                <option value="3">{ratingOption3}</option>
                <option value="4">{ratingOption4}</option>
                <option value="5">{ratingOption5}</option>
              </select>
            </label>
          </div>
          <div className="feedback-buttons-container">
            <button
              type="submit"
              className="feedback-button submit-button"
            >
              {submitButtonText}
            </button>
            <button
              type="button"
              onClick={() => {
                MySwal.close();
                onClose();
              }}
              className="feedback-button close-button"
            >
              {closeButtonText}
            </button>
          </div>
        </form>
      </div>
    );
  };

  useEffect(() => {
    MySwal.fire({
      title: feedbackFormTitle,
      showConfirmButton: false,
      allowOutsideClick: true,
      html: '<div id="feedback-form-container"></div>',
      customClass: {
        popup: 'feedback-modal',
      },
      didOpen: () => {
        ReactDOM.render(<FormContent />, document.getElementById('feedback-form-container'));
      },
      didClose: () => {
        onClose();
      },
    });
  }, [feedbackFormTitle, onClose, MySwal]);
};

export default FeedbackForm;
