import Trs from "components/Trs/TrsComponent";
import React, { useEffect, useState } from "react";
import "styles/OfficeMemberPermission.scss";
import Swal from "sweetalert2";
import api from 'service/api';
import { useSelector } from "react-redux";

const OfficeShareClientPermission = (props) => {
    const [permissionList, setPermissionList] = useState({});
    const [showSave, setShowSave] = useState(false);
    const csrfToken = useSelector((state) => state.user.csrfToken);

    const basic_error_mark = Trs({ jsonRef: "basic_error_mark" });
    const basic_error = Trs({ jsonRef: "basic_error" });
    const office_shared_client_perms_success = Trs({
        jsonRef: "office_shared_client_perms_success",
    });

    useEffect(() => {
        setPermissionList(props.notary.rights);
    }, []);

    const handlePermissionChange = (perm, type, status) => {
        let newPermissionList = { ...permissionList };
        switch (type) {
            case "r":
                if (status && !newPermissionList[perm].includes("r"))
                    newPermissionList[perm] = newPermissionList[perm] + "r";
                else if (
                    !status &&
                    (newPermissionList[perm].includes("w") ||
                        newPermissionList[perm].includes("d") ||
                        newPermissionList[perm].includes("s"))
                )
                    return;
                else if (!status)
                    newPermissionList[perm] = newPermissionList[perm].replace(
                        new RegExp("r", "g"),
                        ""
                    );
                break;
            case "w":
                if (status && !newPermissionList[perm].includes("w")) {
                    newPermissionList[perm] = newPermissionList[perm] + "w";
                    if (!newPermissionList[perm].includes("r"))
                        newPermissionList[perm] = newPermissionList[perm] + "r";
                } else if (
                    !status &&
                    (newPermissionList[perm].includes("d") ||
                        newPermissionList[perm].includes("s"))
                )
                    return;
                else if (!status)
                    newPermissionList[perm] = newPermissionList[perm].replace(
                        new RegExp("w", "g"),
                        ""
                    );
                break;
            case "d":
                if (status && !newPermissionList[perm].includes("d")) {
                    newPermissionList[perm] = newPermissionList[perm] + "d";
                    if (!newPermissionList[perm].includes("r"))
                        newPermissionList[perm] = newPermissionList[perm] + "r";
                    if (!newPermissionList[perm].includes("w"))
                        newPermissionList[perm] = newPermissionList[perm] + "w";
                } else if (!status)
                    newPermissionList[perm] = newPermissionList[perm].replace(
                        new RegExp("d", "g"),
                        ""
                    );
                break;
            case "s":
                if (status && !newPermissionList[perm].includes("s")) {
                    newPermissionList[perm] = newPermissionList[perm] + "s";
                    if (!newPermissionList[perm].includes("r"))
                        newPermissionList[perm] = newPermissionList[perm] + "r";
                    if (!newPermissionList[perm].includes("w"))
                        newPermissionList[perm] = newPermissionList[perm] + "w";
                } else if (!status)
                    newPermissionList[perm] = newPermissionList[perm].replace(
                        new RegExp("s", "g"),
                        ""
                    );
                break;
            default:
                return;
        }
        setShowSave(true);
        setPermissionList(newPermissionList);
    };

    const handleSave = async (event) => {
        const newPermsList = Object.keys(permissionList).reduce((newObj, key) => {
            newObj[`${key}_rights`] = permissionList[key];
            return newObj;
        }, {});
        api.put(
            "notaries/edit-share-client/" + props.client.id + "/",
            newPermsList,
            csrfToken,
            true
        ).then((response) => {
                if (response.status !== 200) {
                    Swal.fire({
                        icon: "error",
                        title: basic_error_mark,
                        text: basic_error,
                    });
                } else {
                    Swal.fire({
                        icon: "success",
                        title: office_shared_client_perms_success,
                        showConfirmButton: false,
                        timer: 2500,
                    });
                    setShowSave(false);
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: basic_error_mark,
                    text: basic_error,
                });
                console.log(error)
            });
    };

    return (
        <React.Fragment>
            <div>
                <h3>
                    <Trs jsonRef="office_share_member_permission_title" />
                </h3>
                <div className="office-member-permission-block-container">
                    {Object.keys(permissionList).map((perm, index) => (
                        <React.Fragment key={index}>
                            <p>
                                <Trs
                                    jsonRef={
                                        "office_share_member_permission_" + perm
                                    }
                                />
                            </p>
                            <div className="checkbox-container">
                                <label htmlFor="readCheckbox">
                                    <Trs jsonRef="office_member_permission_read" />
                                </label>
                                <input
                                    type="checkbox"
                                    id="readCheckbox"
                                    name="readCheckbox"
                                    checked={permissionList[perm].includes("r")}
                                    onChange={(event) => {
                                        handlePermissionChange(
                                            perm,
                                            "r",
                                            event.target.checked
                                        );
                                    }}
                                />
                                <label htmlFor="writeCheckbox">
                                    <Trs jsonRef="office_member_permission_write" />
                                </label>
                                <input
                                    type="checkbox"
                                    id="writeCheckbox"
                                    name="writeCheckbox"
                                    checked={permissionList[perm].includes("w")}
                                    onChange={(event) => {
                                        handlePermissionChange(
                                            perm,
                                            "w",
                                            event.target.checked
                                        );
                                    }}
                                />
                                <label htmlFor="deleteCheckbox">
                                    <Trs jsonRef="office_member_permission_delete" />
                                </label>
                                <input
                                    type="checkbox"
                                    id="deleteCheckbox"
                                    name="deleteCheckbox"
                                    checked={permissionList[perm].includes("d")}
                                    onChange={(event) => {
                                        handlePermissionChange(
                                            perm,
                                            "d",
                                            event.target.checked
                                        );
                                    }}
                                />
                                <label htmlFor="signCheckbox">
                                    <Trs jsonRef="office_member_permission_sign" />
                                </label>
                                <input
                                    type="checkbox"
                                    id="signCheckbox"
                                    name="signCheckbox"
                                    checked={permissionList[perm].includes("s")}
                                    onChange={(event) => {
                                        handlePermissionChange(
                                            perm,
                                            "s",
                                            event.target.checked
                                        );
                                    }}
                                />
                            </div>
                        </React.Fragment>
                    ))}
                    {showSave && (
                        <div className="permission-button-container">
                            <button
                                className="permission-save-button"
                                onClick={handleSave}
                            >
                                <Trs jsonRef="office_member_permission_save" />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default OfficeShareClientPermission;
