import React, { useState } from 'react';
import { Footer } from "flowbite-react";
import { BsInstagram, BsEnvelope, BsFillGeoAltFill } from "react-icons/bs";
import { Link } from 'react-router-dom';
import Trs from "components/Trs/TrsComponent";
import { FeedbackFish } from '@feedback-fish/react';
import FeedbackForm from 'components/FeedbackForm/FeedbackForm';

const NotarioFooter = () => {
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);

  const footerUsefulLinks = Trs({ jsonRef: "footer_useful_links" });
  const footerContact = Trs({ jsonRef: "footer_contact" });
  const footerReportBug = Trs({ jsonRef: "footer_report_bug" });
  const footerFeedback = Trs({ jsonRef: "footer_feedback" });
  const footerCopyright = Trs({ jsonRef: "footer_copyright" });
  const footerFollowUs = Trs({ jsonRef: "footer_follow_us" });
  const notarioEmail = "notario.team@gmail.com";
  const emailSubject = Trs({ jsonRef: "email_subject" });
  const notarioAddress = Trs({ jsonRef: "footer_address" });
  const footer_description = Trs({ jsonRef: "footer_description" });

  return (
    <>
      <Footer container style={{ backgroundColor: "#1A1B25", borderRadius: 0 }}>
        <div className="w-full text-white">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full sm:flex sm:justify-between">
            <div className="flex items-center space-x-2">
              <img src="/logo-notario-white.svg" alt="Notario Logo" className="h-20" />
              <span> {footer_description}</span>
            </div>
            <div className="grid grid-cols-2 gap-8 sm:grid-cols-3 sm:gap-6 text-left text-white">
              <div>
                <h1 title={footerUsefulLinks} className="text-white" />
                <Footer.LinkGroup col>
                  <Link to='/' className="text-white">{Trs({ jsonRef: "basic_home_title" })}</Link>
                  <Link to='/articles' className="text-white">{Trs({ jsonRef: "footer_articles" })}</Link>
                  <Link to='/about-us' className="text-white">{Trs({ jsonRef: "footer_about_us" })}</Link>
                  <Link to='/plans' className="text-white">{Trs({ jsonRef: "footer_plans" })}</Link>
                  <Link to='/faq' className="text-white">{Trs({ jsonRef: "footer_faq" })}</Link>
                </Footer.LinkGroup>
              </div>
              <div>
                <h1 title={footerContact} className="text-white" />
                <Footer.LinkGroup col>
                  <div className="flex items-center text-white">
                    <BsEnvelope className="mr-2" />
                    <a href={`mailto:${notarioEmail}?subject=${emailSubject}`} className="text-white">{notarioEmail}</a>
                  </div>
                  <div className="flex items-center text-white">
                    <BsFillGeoAltFill className="mr-2" />
                    <span>{notarioAddress}</span>
                  </div>
                </Footer.LinkGroup>
              </div>
              <div>
                <h1 title={footerReportBug} className="text-white" />
                <Footer.LinkGroup col>
                  <FeedbackFish projectId="54e51cb163840d">
                    <Footer.Link href="#" className="text-white">{footerReportBug}</Footer.Link>
                  </FeedbackFish>
                  <Footer.Link href="#" className="text-white" onClick={() => setShowFeedbackForm(true)}>
                    {footerFeedback || 'Donnez votre avis'}
                  </Footer.Link>
                </Footer.LinkGroup>
              </div>
            </div>
          </div>
          <Footer.Divider />
          <div className="w-full sm:flex sm:items-center sm:justify-between text-white">
            <Footer.Copyright href="#" by={footerCopyright} year={2024} />
            <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
              <div className="flex items-center">
                <a href="https://www.instagram.com/notario.io/" className="flex items-center text-white" target="_blank" rel="noopener noreferrer">
                  <BsInstagram className="mr-2" />
                  <span>{footerFollowUs}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Footer>

      {showFeedbackForm && (
        <FeedbackForm
          title="Donnez votre avis"
          onClose={() => setShowFeedbackForm(false)}
        />
      )}
    </>
  );
};

export default NotarioFooter;
