import React, { useEffect } from "react";

export const StepsProgress = ({ maxSteps, currentStep }) => {
    const progressPercentage = ((currentStep - 1) / (maxSteps - 1)) * 100;

    return (
        <div className="relative w-full">
            <div className="absolute top-1/2 transform -translate-y-1/2 w-full h-1 bg-gray-300">
                <div
                    className="h-1 bg-primary transition-all duration-500 animate-left-to-right"
                    style={{ width: `${progressPercentage}%` }}
                ></div>
            </div>

            <div className="relative flex justify-between items-center">
                {Array.from({ length: maxSteps }, (_, index) => {
                    const step = index + 1;
                    const isActive = step <= currentStep;

                    return (
                        <div key={step} className="flex flex-col items-center">
                            <div
                                className={`w-7 h-7 rounded-full flex items-center justify-center text-white ${isActive ? "bg-primary" : "bg-gray-300"
                                    } transition-all duration-500`}
                                style={{
                                    transform: isActive ? "scale(1.1)" : "scale(1)",
                                }}
                            >
                                {step}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};


export const FormDocStepsProgress = ({ maxSteps, currentStep, stepName }) => {
    const progressPercentage = ((currentStep - 1) / (maxSteps - 1)) * 100;

    return (
        <div className="relative w-full">
            <div className="absolute top-1/2 transform -translate-y-1/2 w-full h-1 bg-grey overflow-hidden">
                <div
                    className="relative h-1 bg-primary transition-all duration-500 animate-left-to-right"
                    style={{ width: `${progressPercentage}%` }}
                ></div>
            </div>

            <div className="relative flex justify-between items-center">
                {Array.from({ length: maxSteps }, (_, index) => {
                    const step = index + 1;
                    const isActive = step <= currentStep;

                    return (
                        <div key={step} className="flex flex-col items-center">
                            <div
                                className={`w-7 h-7 rounded-full flex items-center justify-center text-white ${isActive ? "bg-primary" : "bg-gray-300"
                                    } transition-all duration-500`}
                                style={{
                                    transform: isActive ? "scale(1.2)" : "scale(1.1)",
                                }}
                            >
                                {step}
                            </div>
                           {stepName && stepName[step - 1] && (
                                <p className="absolute pt-9 text-base text-center text-black opacity-100">
                                    {stepName[step - 1]}
                                </p>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};


