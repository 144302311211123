
export const generateStepsForTemplate = (templateContent) => {
    if (!templateContent || !templateContent.file) return [];
    const type = templateContent.name.toUpperCase();
    switch (type) {
        case "PROCURATION":
            return ["Mandant", "Mandataire", "Informations Générales"];
        case "MARIAGE":
            return ["Époux", "Épouse", "Informations Générales"];
        case "DONATION":
            return ["Donateur", "Bénéficiaire", "Informations Générales"];
        case "RECONNAISSANCE_DE_DETTE":
            return ["Donateur", "Bénéficiaire", "Informations Générales"];
        default:
            return [];
    }
};


// use to get all steps to generete fields
export const getStepData = (step, content) => {
    if (!content || !content.file) return {};
    const type = content.name.toUpperCase();

    switch (type) {
        case "PROCURATION":
            switch (step) {
                case 1:
                    return content.file.PROCURATION.MANDANT || {};
                case 2:
                    return content.file.PROCURATION.MANDATAIRE || {};
                case 3:
                    return {
                        LIEU: content.file.PROCURATION.LIEU || "",
                        DATE: content.file.PROCURATION.DATE || "",
                        NOTAIRE:
                            content.file.PROCURATION.NOTTAIRE || "",
                        DATE_DEBUT:
                            content.file.PROCURATION.DATE_DEBUT || "",
                        DATE_FIN:
                            content.file.PROCURATION.DATE_FIN || "",
                        OPERATIONS:
                            content.file.PROCURATION.OPERATIONS || "",
                    };
                default:
                    return {};
            }
        case "MARIAGE":
            switch (step) {
                case 1:
                    return content.file.MARIAGE.EPOUX || {};
                case 2:
                    return content.file.MARIAGE.EPOUSE || {};
                case 3:
                    return {
                        LIEU: content.file.MARIAGE.LIEU || "",
                        DATE_SIGNATURE:
                            content.file.MARIAGE.DATE_SIGNATURE || "",
                        NOTAIRE: content.file.MARIAGE.NOTTAIRE || "",
                        NUMERO_REGISTRE:
                            content.file.MARIAGE.NUMERO_REGISTRE || "",
                        DATE_MARIAGE:
                            content.file.MARIAGE.DATE_MARIAGE || "",
                        REGIME: content.file.MARIAGE.REGIME || "",
                    };
                default:
                    return {};
            }
        case "DONATION":
            switch (step) {
                case 1:
                    return content.file.DONATION.DONATEUR || {};
                case 2:
                    return content.file.DONATION.BENEFICIAIRE || {};
                case 3:
                    return {
                        BIENS: content.file.DONATION.BIENS || "",
                        CONDITIONS:
                            content.file.DONATION.CONDITIONS || "",
                        NOTAIRE: content.file.DONATION.NOTTAIRE || "",
                        LIEU: content.file.DONATION.LIEU || "",
                        NUMERO_REGISTRE:
                            content.file.DONATION.NUMERO_REGISTRE ||
                            "",
                        DATE_SIGNATURE:
                            content.file.DONATION.DATE_SIGNATURE || "",
                    };
                default:
                    return {};
            }
        case "RECONNAISSANCE_DE_DETTE":
            switch (step) {
                case 1:
                    return content.file.RECONNAISSANCE_DE_DETTE.DONATEUR || {};
                case 2:
                    return content.file.RECONNAISSANCE_DE_DETTE.BENEFICIAIRE || {};
                case 3:
                    return {
                        ARGENT: content.file.RECONNAISSANCE_DE_DETTE.ARGENT || "",
                        DEADLINE:
                            content.file.RECONNAISSANCE_DE_DETTE.DEADLINE || "",
                        NOTAIRE: content.file.RECONNAISSANCE_DE_DETTE.NOTTAIRE || "",
                        LIEU: content.file.RECONNAISSANCE_DE_DETTE.LIEU || "",
                        NUMERO_REGISTRE:
                            content.file.RECONNAISSANCE_DE_DETTE.NUMERO_REGISTRE ||
                            "",
                        DATE_SIGNATURE:
                            content.file.RECONNAISSANCE_DE_DETTE.DATE_SIGNATURE || "",
                    };
                default:
                    return {};
            }
        default:
            return {};
    }
};

export const renderLabelWithKey = (key) => {
    switch (key) {
        case "SEXE":
            return "Sexe";
        case "NOM":
            return "Nom";
        case "PRENOM":
            return "Prénom";
        case "DATE_NAISSANCE":
            return "Date de naissance";
        case "MANDANT":
            return "Mandant";
        case "MANDATAIRE":
            return "Mandataire";
        case "LIEU":
            return "Lieu";
        case "DATE":
            return "Date";
        case "NOTAIRE":
            return "Notaire";
        case "DATE_DEBUT":
            return "Date de début";
        case "DATE_FIN":
            return "Date de fin";
        case "OPERATIONS":
            return "Opérations";
        case "EPOUX":
            return "Époux";
        case "EPOUSE":
            return "Épouse";
        case "CODE_POSTAL":
            return "Code postal";
        case "VILLE":
            return "Ville";
        case "NUMERO":
            return "Numéro de téléphone";
        case "VILLE_NAISSANCE":
            return "Ville de naissance";
        case "ADRESSE":
            return "Adresse";
        case "DATE_SIGNATURE":
            return "Date de signature";
        case "NUMERO_REGISTRE":
            return "Numéro de registre";
        case "DATE_MARIAGE":
            return "Date de mariage";
        case "REGIME":
            return "Régime matrimonial";
        default:
            return key;
    }
}


export const RenderFormsContent = ({formContent, activeStep, formData, errors, handleInputChange, handleSelectChange}) => {
    const stepFields = getStepData(activeStep, formContent);

    return (
        <div className="w-full grid grid-cols-2 md:grid-cols-3 gap-x-20 gap-y-7">
            {Object.entries(stepFields).map(([key, value]) => {
                if (key === "SEXE") {
                    return (
                        <div key={key} className="mb-4">
                            <label className="block text-base font-medium mb-2">{renderLabelWithKey(key)}:</label>
                            <select
                                value={formData[`${activeStep}_${key}`] || ""}
                                onChange={(e) => handleSelectChange(e, key)}
                                className={`w-full rounded-lg border ${errors[`${activeStep}_${key}`]
                                    ? "border-red-500"
                                    : "border-gray-300"
                                    } p-2`}
                            >
                                <option value="" disabled>
                                    SEXE
                                </option>
                                <option value="H">Homme</option>
                                <option value="F">Femme</option>
                            </select>
                            {errors[`${activeStep}_${key}`] && (
                                <p className="text-red-500 text-sm mt-1">
                                    {errors[`${activeStep}_${key}`]}
                                </p>
                            )}
                        </div>
                    );
                } else {
                    return (
                        <div key={key} className="mb-4">
                            <label className="text-base block font-medium mb-2">{renderLabelWithKey(key)}:</label>
                            <input
                                type="text"
                                className={`w-full rounded-lg border ${errors[`${activeStep}_${key}`]
                                    ? "border-red-500"
                                    : "border-gray-300"
                                    } p-2`}
                                value={formData[`${activeStep}_${key}`] || ""}
                                onChange={(e) => handleInputChange(e, key)}
                                onSelect={(e) => handleInputChange(e, key)}
                            />
                            {errors[`${activeStep}_${key}`] && (
                                <p className="text-red-500 text-sm mt-1">
                                    {errors[`${activeStep}_${key}`]}
                                </p>
                            )}
                        </div>
                    );
                }
            })}
        </div>
    );
};