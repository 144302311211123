import React, { useState, useEffect, useRef } from "react";
import {
    Box,
    IconButton,
    Modal,
    Typography,
    List,
    ListItem,
    ListItemText,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Divider,
    Tooltip,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import apiService from "service/api";
import { useSelector } from "react-redux";
import Trs from "components/Trs/TrsComponent";
import useInfiniteScroll from "customHooks/useInfiniteScroll";

const style = {
    position: "absolute",
    top: "80px",
    right: "10px",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: "8px",
};

const listStyle = {
    maxHeight: "300px", // Limite la hauteur à 300px
    overflowY: "auto", // Ajoute un défilement vertical si le contenu dépasse la hauteur
};

function NotificationZone() {
    const csrfToken = useSelector((state) => state.user.csrfToken);
    const [formattedNotifications, setFormattedNotifications] = useState([]);
    const [filteredNotifications, setFilteredNotifications] = useState([]);
    const [sortedNotifications, setSortedNotifications] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [sortOrder, setSortOrder] = useState("desc");
    const [selectedType, setSelectedType] = useState("all");
    const [filterValue, setFilterValue] = useState("all");
    const scrollContainerRef = useRef();
    const labelTsr = Trs({ jsonRef: "notification_zone_modal_label" });
    const notification_zone_all = Trs({ jsonRef: "notification_zone_all" });
    const notification_zone_oth = Trs({ jsonRef: "notification_zone_oth" });
    const notification_zone_mal = Trs({ jsonRef: "notification_zone_mal" });
    const notification_zone_upd = Trs({ jsonRef: "notification_zone_upd" });
    const notification_zone_inv = Trs({ jsonRef: "notification_zone_inv" });
    const notification_zone_del = Trs({ jsonRef: "notification_zone_del" });

    const refreshNotifications = async (page = 1) => {
        return apiService
            .get("notification/get/?page=" + page, csrfToken, true)
            .then((response) => {
                return {
                    newItems: response.data.data,
                    hasMoreItems: response.data.pagination.has_next,
                };
            })
            .catch((error) => {
                console.log(error);
                return {
                    newItems: [],
                    hasMoreItems: false,
                };
            });
    };

    const {
        items: notifications = [],
        setItems: setNotifications,
        hasMore,
        forceRefresh,
    } = useInfiniteScroll(refreshNotifications, 1, scrollContainerRef);

    useEffect(() => {
        if (Array.isArray(notifications)) {
            let newFormattedNotifications = notifications.map((notification) => {
                const parsedDateTime = new Date(notification.created_at);
                const formattedDate = `${parsedDateTime.getFullYear()}/${String(
                    parsedDateTime.getMonth() + 1
                ).padStart(2, "0")}/${String(parsedDateTime.getDate()).padStart(
                    2,
                    "0"
                )}`;
                const formattedTime = `${String(parsedDateTime.getHours()).padStart(
                    2,
                    "0"
                )}:${String(parsedDateTime.getMinutes()).padStart(2, "0")}`;
                return {
                    uid: notification.uid,
                    title: notification.title,
                    date: formattedDate,
                    time: formattedTime,
                    type: notification.type,
                };
            });
            setFormattedNotifications(newFormattedNotifications);
        } else {
            setFormattedNotifications([]);
        }
    }, [notifications]);
    

    useEffect(() => {
        const newFilteredNotifications = formattedNotifications.filter(
            (notification) => {
                return (
                    selectedType === "all" || notification.type === selectedType
                );
            }
        );
        setFilteredNotifications(newFilteredNotifications);
    }, [formattedNotifications, selectedType]);

    useEffect(() => {
        const newSortedNotifications =
            sortOrder === "asc"
                ? [...filteredNotifications].sort((a, b) =>
                      a.date.localeCompare(b.date)
                  )
                : [...filteredNotifications].sort((a, b) =>
                      b.date.localeCompare(a.date)
                  );
        setSortedNotifications(newSortedNotifications);
    }, [filteredNotifications, sortOrder]);

    const openModal = () => {
        setModalIsOpen(true);
        forceRefresh(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleSortOrderChange = (event) => {
        setSortOrder(event.target.value);
    };

    const handleFilterChange = (event) => {
        const value = event.target.value;
        setFilterValue(value);
        setSelectedType(value);
    };

    const handleDeleteNotification = (uid) => {
        try {
            apiService
                .delete("notification/delete/", csrfToken, true, { uid: uid })
                .then((response) => {
                    setNotifications(
                        notifications.filter(
                            (notification) => notification.uid !== uid
                        )
                    );
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Box>
            <Tooltip title="Notifications">
                <IconButton color="inherit" onClick={openModal}>
                    <NotificationsIcon style={{ color: "white" }} />
                </IconButton>
            </Tooltip>
            <Modal
                open={modalIsOpen}
                onClose={closeModal}
                aria-labelledby={labelTsr}
            >
                <Box sx={style}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h6" component="h2">
                            <Trs jsonRef="notification_zone_modal_title" />
                        </Typography>
                        <IconButton onClick={closeModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="filter-label">
                            <Trs jsonRef="notif_filter_per_type" />
                        </InputLabel>
                        <Select
                            labelId="filter-label"
                            value={filterValue}
                            label={<Trs jsonRef="notif_filter_per_type" />}
                            onChange={handleFilterChange}
                        >
                            <MenuItem value="all">
                                {notification_zone_all}
                            </MenuItem>
                            <MenuItem value="OTH">
                                {notification_zone_oth}
                            </MenuItem>
                            <MenuItem value="MAL">
                                {notification_zone_mal}
                            </MenuItem>
                            <MenuItem value="UPD">
                                {notification_zone_upd}
                            </MenuItem>
                            <MenuItem value="INV">
                                {notification_zone_inv}
                            </MenuItem>
                            <MenuItem value="DEL">
                                {notification_zone_del}
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel id="sort-label">
                            <Trs jsonRef="notif_tri_order" />
                        </InputLabel>
                        <Select
                            labelId="sort-label"
                            value={sortOrder}
                            label={<Trs jsonRef="notif_tri_order" />}
                            onChange={handleSortOrderChange}
                        >
                            <MenuItem value="desc">
                                <Trs jsonRef="notif_more_recent" />
                            </MenuItem>
                            <MenuItem value="asc">
                                <Trs jsonRef="notif_more_ancient" />
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <List sx={listStyle} ref={scrollContainerRef}>
                        {sortedNotifications.map(
                            (formattedNotification, index) => (
                                <ListItem
                                    key={formattedNotification.uid || index}
                                    secondaryAction={
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() =>
                                                handleDeleteNotification(
                                                    formattedNotification.uid
                                                )
                                            }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemText
                                        primary={formattedNotification.title}
                                        secondary={`${formattedNotification.date} ${formattedNotification.time}`}
                                    />
                                </ListItem>
                            )
                        )}
                        {sortedNotifications &&
                            sortedNotifications.length > 0 &&
                            !hasMore && (
                                <p>
                                    <Trs jsonRef={"all_load"} />
                                </p>
                            )}
                    </List>
                </Box>
            </Modal>
        </Box>
    );
}

export default NotificationZone;
