import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import api from "service/api";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Trs from "components/Trs/TrsComponent";
import ConfirmationOnLeave from "components/utils/ConfirmationOnLeave";

const Draft = () => {
  const csrfToken = useSelector((state) => state.user.csrfToken);
  const [templatesAvailable, setTemplatesAvailable] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [popupContent, setPopupContent] = useState(null);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState([]);

  const basic_error = Trs({ jsonRef: "basic_error" });
  const basic_success_title = Trs({ jsonRef: "basic_success_title" });
  const form_error_text = Trs({ jsonRef: "form_error_text" });
  const form_success_text = Trs({ jsonRef: "form_success_text" });
  const client_ask_new_doc_only_letters = Trs({
    jsonRef: "client_ask_new_doc_only_letters",
  });
  const client_ask_new_doc_date_format = Trs({
    jsonRef: "client_ask_new_doc_date_format",
  });
  const client_ask_new_doc_five_digits = Trs({
    jsonRef: "client_ask_new_doc_five_digits",
  });
  const client_ask_new_doc_ten_digits = Trs({
    jsonRef: "client_ask_new_doc_ten_digits",
  });
  const client_ask_new_doc_title = Trs({
    jsonRef: "client_ask_new_doc_title",
  });
  const client_ask_new_doc_demand_from = Trs({
    jsonRef: "client_ask_new_doc_demand_from",
  });
  const basic_previous = Trs({ jsonRef: "basic_previous" });
  const basic_next = Trs({ jsonRef: "basic_next" });
  const basic_submit = Trs({ jsonRef: "basic_submit" });
  const basic_close = Trs({ jsonRef: "basic_close" });

  const validateField = (value, fieldName) => {
    if (!value || value.trim() === "") {
      return `${fieldName} est requis`;
    }
    if (
      fieldName.toLowerCase().includes("nom") ||
      fieldName.toLowerCase().includes("prenom")
    ) {
      if (!/^[A-Za-z\s-]+$/.test(value)) {
        return `${fieldName} ${client_ask_new_doc_only_letters}`;
      }
    }
    if (fieldName.toLowerCase().includes("date")) {
      if (!/^\d{2}\/\d{2}\/\d{4}$/.test(value)) {
        return `${fieldName} ${client_ask_new_doc_date_format}`;
      }
    }
    if (fieldName.toLowerCase().includes("code_postal")) {
      if (!/^\d{5}$/.test(value)) {
        return `${fieldName} ${client_ask_new_doc_five_digits}`;
      }
    }
    if (fieldName.toLowerCase().includes("ville")) {
      if (!/^[A-Za-z\s-]+$/.test(value)) {
        return `${fieldName} ${client_ask_new_doc_only_letters}`;
      }
    }
    if (fieldName.toLowerCase().includes("numero")) {
      if (!/^\d{10}$/.test(value)) {
        return `${fieldName} ${client_ask_new_doc_ten_digits}`;
      }
    }
    return null;
  };

  const fetchInfo = async () => {
    try {
      const response = await api.get(
        `files/get-templates/`,
        csrfToken,
        true
      );
      setTemplatesAvailable(response.data.templates);
    } catch (error) {
      console.error("Error loading info:", error);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  const error_when_loading_datas = Trs({
    jsonRef: "error_when_loading_datas",
  });
  const error_when_loading_template_content = Trs({
    jsonRef: "error_when_loading_template_content",
  });
  const unknown_content_for_template_popup = Trs({
    jsonRef: "unknown_content_for_template_popup",
  });

  const handleCreateButtonClick = async (templateName) => {
    try {
      setOpenPopup(true);
    } catch (error) {
      console.error(error_when_loading_datas, error);
    }
    let content = null;
    let documentSteps = [];
    if (templateName) {
      try {
        const response = await api.get(
          "files/get-template/" + templateName + "/",
          csrfToken,
          true
        );
        content = response.data;
        documentSteps = generateStepsForTemplate(content);
        setSteps(documentSteps);
      } catch (error) {
        console.error(error_when_loading_datas, error);
        content = error_when_loading_template_content;
      }
    } else {
      content = unknown_content_for_template_popup;
    }
    setPopupContent(content);
  };

  const generateStepsForTemplate = (templateContent) => {
    if (!templateContent || !templateContent.file) return [];
    const type = templateContent.name.toUpperCase();
    switch (type) {
      case "PROCURATION":
        return ["Mandant", "Mandataire", "Informations Générales"];
      case "MARIAGE":
        return ["Époux", "Épouse", "Informations Générales"];
      case "DONATION":
        return ["Donateur", "Bénéficiaire", "Informations Générales"];
      default:
        return [];
    }
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    setActiveStep(0);
    setFormData({});
    setErrors({});
  };

  const handleNext = () => {
    const currentStepData = getStepData(activeStep);
    const stepErrors = {};

    Object.entries(currentStepData).forEach(([key, value]) => {
      const formKey = `${activeStep}_${key}`;
      const error = validateField(formData[formKey], key);
      if (error) {
        stepErrors[formKey] = error;
      }
    });

    if (Object.keys(stepErrors).length === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setErrors((prevErrors) => ({ ...prevErrors, ...stepErrors }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, ...stepErrors }));
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleInputChange = (e, key) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [`${activeStep}_${key}`]: value,
    }));
  };

  const handleSelectChange = (e, key) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [`${activeStep}_${key}`]: value,
    }));
  };

  const handleSubmit = async () => {
    const data = {};
    steps.forEach((_, index) => {
      const stepData = getStepData(index);
      Object.entries(stepData).forEach(([key, value]) => {
        if (typeof value === "object") {
          Object.entries(value).forEach(([subKey, subValue]) => {
            if (!data[index]) data[index] = {};
            data[index][subKey] =
              formData[`${index}_${key}_${subKey}`] || "";
          });
        } else {
          if (!data[index]) data[index] = {};
          data[index][key] = formData[`${index}_${key}`] || "";
        }
      });
    });

    const type = popupContent?.name.toUpperCase();
    let formattedData;

    switch (type) {
      case "PROCURATION":
        formattedData = {
          PROCURATION: {
            MANDANT: data[0] || {},
            MANDATAIRE: data[1] || {},
            LIEU: data[2]?.LIEU || "",
            DATE: data[2]?.DATE || "",
            NOTAIRE: data[2]?.NOTAIRE || "",
            DATE_DEBUT: data[2]?.DATE_DEBUT || "",
            DATE_FIN: data[2]?.DATE_FIN || "",
            OPERATIONS: data[2]?.OPERATIONS || "",
          },
        };
        break;
      case "MARIAGE":
        formattedData = {
          MARIAGE: {
            EPOUX: data[0] || {},
            EPOUSE: data[1] || {},
            LIEU: data[2]?.LIEU || "",
            DATE_SIGNATURE: data[2]?.DATE_SIGNATURE || "",
            NOTAIRE: data[2]?.NOTAIRE || "",
            NUMERO_REGISTRE: data[2]?.NUMERO_REGISTRE || "",
            DATE_MARIAGE: data[2]?.DATE_MARIAGE || "",
            REGIME: data[2]?.REGIME || "",
          },
        };
        break;
      case "DONATION":
        formattedData = {
          DONATION: {
            DONATEUR: data[0] || {},
            BENEFICIAIRE: data[1] || {},
            BIENS: data[2]?.BIENS || "",
            CONDITIONS: data[2]?.CONDITIONS || "",
            NOTAIRE: data[2]?.NOTAIRE || "",
            LIEU: data[2]?.LIEU || "",
            NUMERO_REGISTRE: data[2]?.NUMERO_REGISTRE || "",
            DATE_SIGNATURE: data[2]?.DATE_SIGNATURE || "",
          },
        };
        break;
      default:
        console.error("Unknown document type:", type);
        return;
    }

    const requestData = {
      type: "OTH",
      data: formattedData,
      name: popupContent.name,
    };

    try {
      await api.post(
        "files/fill-template/" + popupContent.name + "/",
        requestData,
        csrfToken,
        true
      );
      Swal.fire({
        icon: "success",
        title: basic_success_title,
        text: form_success_text,
      });
    } catch (error) {
      console.log("Submission error:", error);
      Swal.fire({
        icon: "error",
        title: basic_error,
        text: form_error_text,
      });
    }

    handleClosePopup();
  };

  const getStepData = (step) => {
    if (!popupContent || !popupContent.file) return {};
    const type = popupContent.name.toUpperCase();
    switch (type) {
      case "PROCURATION":
        switch (step) {
          case 0:
            return popupContent.file.PROCURATION.MANDANT || {};
          case 1:
            return popupContent.file.PROCURATION.MANDATAIRE || {};
          case 2:
            return {
              LIEU: popupContent.file.PROCURATION.LIEU || "",
              DATE: popupContent.file.PROCURATION.DATE || "",
              NOTAIRE:
                popupContent.file.PROCURATION.NOTTAIRE || "",
              DATE_DEBUT:
                popupContent.file.PROCURATION.DATE_DEBUT || "",
              DATE_FIN:
                popupContent.file.PROCURATION.DATE_FIN || "",
              OPERATIONS:
                popupContent.file.PROCURATION.OPERATIONS || "",
            };
          default:
            return {};
        }
      case "MARIAGE":
        switch (step) {
          case 0:
            return popupContent.file.MARIAGE.EPOUX || {};
          case 1:
            return popupContent.file.MARIAGE.EPOUSE || {};
          case 2:
            return {
              LIEU: popupContent.file.MARIAGE.LIEU || "",
              DATE_SIGNATURE:
                popupContent.file.MARIAGE.DATE_SIGNATURE || "",
              NOTAIRE: popupContent.file.MARIAGE.NOTTAIRE || "",
              NUMERO_REGISTRE:
                popupContent.file.MARIAGE.NUMERO_REGISTRE || "",
              DATE_MARIAGE:
                popupContent.file.MARIAGE.DATE_MARIAGE || "",
              REGIME: popupContent.file.MARIAGE.REGIME || "",
            };
          default:
            return {};
        }
      case "DONATION":
        switch (step) {
          case 0:
            return popupContent.file.DONATION.DONATEUR || {};
          case 1:
            return popupContent.file.DONATION.BENEFICIAIRE || {};
          case 2:
            return {
              BIENS: popupContent.file.DONATION.BIENS || "",
              CONDITIONS:
                popupContent.file.DONATION.CONDITIONS || "",
              NOTAIRE: popupContent.file.DONATION.NOTTAIRE || "",
              LIEU: popupContent.file.DONATION.LIEU || "",
              NUMERO_REGISTRE:
                popupContent.file.DONATION.NUMERO_REGISTRE ||
                "",
              DATE_SIGNATURE:
                popupContent.file.DONATION.DATE_SIGNATURE || "",
            };
          default:
            return {};
        }
      default:
        return {};
    }
  };

  const renderStepContent = (step) => {
    const stepFields = getStepData(step);
    return (
      <div>
        {Object.entries(stepFields).map(([key, value]) => {
          if (typeof value === "object") {
            return (
              <div key={key}>
                <Typography variant="h6" gutterBottom>
                  {key}
                </Typography>
                {Object.entries(getStepData(activeStep)).map(
                  ([key, value]) => (
                    <TextField
                      key={key}
                      label={key}
                      value={
                        formData[
                        `${activeStep}_${key}`
                        ] || ""
                      }
                      onChange={(e) =>
                        handleInputChange(e, key)
                      }
                      helperText={
                        errors[`${activeStep}_${key}`]
                      }
                      error={Boolean(
                        errors[`${activeStep}_${key}`]
                      )}
                      fullWidth
                      margin="normal"
                    />
                  )
                )}
              </div>
            );
          } else {
            if (key === "SEXE") {
              return (
                <Select
                  key={key}
                  value={formData[`${step}_${key}`] || ""}
                  onChange={(e) => handleSelectChange(e, key)}
                  fullWidth
                  margin="normal"
                  displayEmpty
                  error={!!errors[`${step}_${key}`]}
                >
                  <MenuItem value="" disabled>
                    SEXE
                  </MenuItem>
                  <MenuItem value="H">Homme</MenuItem>
                  <MenuItem value="F">Femme</MenuItem>
                </Select>
              );
            } else {
              return (
                <TextField
                  key={key}
                  label={key}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={formData[`${step}_${key}`] || ""}
                  onChange={(e) => handleInputChange(e, key)}
                  error={!!errors[`${step}_${key}`]}
                  helperText={errors[`${step}_${key}`]}
                />
              );
            }
          }
        })}
      </div>
    );
  };

  const getDocumentIcon = (templateName) => {
    switch (templateName.toUpperCase()) {
      case "PROCURATION":
        return "fa-solid fa-handshake"; // Icône pour procuration
      case "MARIAGE":
        return "fa fa-heart"; // Icône pour mariage
      case "DONATION":
        return "fa fa-gift"; // Icône pour donation
      default:
        return "fa fa-file-text-o"; // Icône générique
    }
  };

  return (
    <div className="document-page">
      <ConfirmationOnLeave />
      <div style={{ textAlign: "center", padding: "20px 0px" }}>
        <h1 style={{ fontSize: "2rem" }}>{client_ask_new_doc_title}</h1>
      </div>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {templatesAvailable.map((template, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card elevation={3}>
                <CardActionArea
                  onClick={() =>
                    handleCreateButtonClick(template)
                  }
                >
                  <CardMedia
                    style={{
                      height: 140,
                      backgroundColor: "#f0f0f0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <i
                      className={getDocumentIcon(
                        template
                      )}
                      style={{
                        fontSize: "4rem",
                        color: "#351EA4",
                      }}
                    ></i>
                  </CardMedia>
                  <CardContent>
                    <Typography
                      variant="h6"
                      component="h2"
                      gutterBottom
                      align="center"
                    >
                      {template.charAt(0).toUpperCase() +
                        template.slice(1)}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {popupContent && (
        <Dialog open={openPopup} onClose={handleClosePopup} fullWidth>
          <DialogTitle>
            {client_ask_new_doc_demand_from} {popupContent.name}
          </DialogTitle>
          <DialogContent>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {renderStepContent(activeStep)}
          </DialogContent>
          <DialogActions>
            <Button
              disabled={activeStep === 0}
              style={{ color: "#351EA4" }}
              onClick={handleBack}
            >
              {basic_previous}
            </Button>
            {activeStep === steps.length - 1 ? (
              <Button
                style={{ color: "#351EA4" }}
                onClick={handleSubmit}
              >
                {basic_submit}
              </Button>
            ) : (
              <Button
                style={{ color: "#351EA4" }}
                onClick={handleNext}
              >
                {basic_next}
              </Button>
            )}
            <Button
              style={{ color: "#351EA4" }}
              onClick={handleClosePopup}
            >
              {basic_close}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default Draft;
