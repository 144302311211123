import { getStepData } from "./FormsUtils";

export const HandleFormDocsSubmit = (formSteps, formData, formContent) => {
    const data = {};

    formSteps.forEach((_, index) => {
        const adjustedIndex = index + 1;
        const stepData = getStepData(adjustedIndex, formContent);

        Object.entries(stepData).forEach(([key, value]) => {
            if (typeof value === "object") {
                Object.entries(value).forEach(([subKey, subValue]) => {
                    if (!data[adjustedIndex]) data[adjustedIndex] = {};
                    data[adjustedIndex][subKey] =
                        formData[`${adjustedIndex}_${key}_${subKey}`] || "";
                });
            } else {
                if (!data[adjustedIndex]) data[adjustedIndex] = {};
                data[adjustedIndex][key] = formData[`${adjustedIndex}_${key}`] || "";
            }
        });
    });

    const type = formContent?.name.toUpperCase();
    let formattedData;

    switch (type) {
        case "PROCURATION":
            formattedData = {
                PROCURATION: {
                    MANDANT: data[1] || {},
                    MANDATAIRE: data[2] || {},
                    LIEU: data[3]?.LIEU || "",
                    DATE: data[3]?.DATE || "",
                    NOTAIRE: data[3]?.NOTAIRE || "",
                    DATE_DEBUT: data[3]?.DATE_DEBUT || "",
                    DATE_FIN: data[3]?.DATE_FIN || "",
                    OPERATIONS: data[3]?.OPERATIONS || "",
                },
            };
            break;
        case "MARIAGE":
            formattedData = {
                MARIAGE: {
                    EPOUX: data[1] || {},
                    EPOUSE: data[2] || {},
                    LIEU: data[3]?.LIEU || "",
                    DATE_SIGNATURE: data[3]?.DATE_SIGNATURE || "",
                    NOTAIRE: data[3]?.NOTAIRE || "",
                    NUMERO_REGISTRE: data[3]?.NUMERO_REGISTRE || "",
                    DATE_MARIAGE: data[3]?.DATE_MARIAGE || "",
                    REGIME: data[3]?.REGIME || "",
                },
            };
            break;
        case "DONATION":
            formattedData = {
                DONATION: {
                    DONATEUR: data[1] || {},
                    BENEFICIAIRE: data[2] || {},
                    BIENS: data[3]?.BIENS || "",
                    CONDITIONS: data[3]?.CONDITIONS || "",
                    NOTAIRE: data[3]?.NOTAIRE || "",
                    LIEU: data[3]?.LIEU || "",
                    NUMERO_REGISTRE: data[3]?.NUMERO_REGISTRE || "",
                    DATE_SIGNATURE: data[3]?.DATE_SIGNATURE || "",
                },
            };
            break;
        case "RECONNAISSANCE_DE_DETTE":
            formattedData = {
                RECONNAISSANCE_DE_DETTE: {
                    DONATEUR: data[1] || {},
                    BENEFICIAIRE: data[2] || {},
                    ARGENT: data[3]?.ARGENT || "",
                    DEADLINE: data[3]?.DEADLINE || "",
                    NOTAIRE: data[3]?.NOTAIRE || "",
                    LIEU: data[3]?.LIEU || "",
                    NUMERO_REGISTRE: data[3]?.NUMERO_REGISTRE || "",
                    DATE_SIGNATURE: data[3]?.DATE_SIGNATURE || "",
                },
            };
            break;
        default:
            console.error("Unknown document type:", type);
            return;
    }

    const requestData = {
        type: "OTH",
        data: formattedData,
        name: formContent?.name,
    };

    return requestData;
};