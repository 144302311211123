import React, { useState, useEffect } from "react";
import {
    Button,
    TextField,
    Typography,
    Container,
    Grid,
    InputLabel,
    Input,
    FormControl,
} from "@mui/material";
import apiService from "service/api";
import { useSelector } from "react-redux";
import "styles/Articles.scss";
import Swal from "sweetalert2";
import Trs from "components/Trs/TrsComponent";
import ConfirmationOnLeave from "components/utils/ConfirmationOnLeave";
import Loader from "components/Loader/Loader";

const CreateArticleForm = ({
    onCreate,
    editingArticle,
    onCancelEdit,
    fetchData,
}) => {
    const basic_error = Trs({ jsonRef: "basic_error" });
    const basic_error_mark = Trs({ jsonRef: "basic_error_mark" });
    const basic_success_title = Trs({ jsonRef: "basic_success_title" });
    const article_updated_success_text = Trs({
        jsonRef: "article_updated_success_text",
    });
    const article_created_success_text = Trs({
        jsonRef: "article_created_success_text",
    });

    const [loading, setLoading] = useState(false);

    const user = useSelector((state) => state.user.user);
    const csrfToken = useSelector((state) => state.user.csrfToken);

    const [formData, setFormData] = useState({
        title: "",
        description: "",
        content: "",
        author: "",
    });

    useEffect(() => {
        if (editingArticle) {
            setFormData({
                title: editingArticle.title,
                description: editingArticle.description,
                content: editingArticle.content,
                author: user.id,
            });
        }
    }, [editingArticle]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData((prevData) => ({ ...prevData, image: file }));
    };

    const handleAddNotification = (title, content) => {
        const newNotification = {
            title: title,
            content: content,
            type: "OTH",
        };
        try {
            apiService
                .post("notification/add/", newNotification, csrfToken, true)
                .then((response) => {})
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };

    const article_update = Trs({ jsonRef: "article_update" });
    const article_entitled = Trs({ jsonRef: "article_entitled" });
    const has_been_updated = Trs({ jsonRef: "has_been_updated" });
    const new_article_created = Trs({ jsonRef: "new_article_created" });
    const new_article_entitled = Trs({ jsonRef: "new_article_entitled" });
    const has_been_created = Trs({ jsonRef: "has_been_created" });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const formDataToSend = new FormData();
            formDataToSend.append("title", formData.title);
            formDataToSend.append("description", formData.description);
            formDataToSend.append("content", formData.content);
            formDataToSend.append("author", user.id);
            formDataToSend.append("image", formData.image);

            if (editingArticle) {
                await apiService.put(
                    `articles/update/${editingArticle.uid}/`,
                    formData,
                    csrfToken,
                    true,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            "X-CSRFToken": csrfToken,
                        },
                    }
                );
                Swal.fire({
                    icon: "success",
                    title: basic_success_title,
                    text: article_updated_success_text,
                });

                handleAddNotification(
                    `${article_update} ${formData.title}`,
                    `${article_entitled} ${formData.title} ${has_been_updated}`
                );
            } else {
                const response = await apiService.postwFile(
                    "articles/add/",
                    formDataToSend,
                    csrfToken,
                    true,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            "X-CSRFToken": csrfToken,
                        },
                    }
                );
                Swal.fire({
                    icon: "success",
                    title: basic_success_title,
                    text: article_created_success_text,
                });

                handleAddNotification(
                    `${new_article_created} ${formData.title}`,
                    `${new_article_entitled} ${formData.title} ${has_been_created}`
                );
            }

            onCreate();
            fetchData();
        } catch (error) {
            console.error("Error creating/editing article:", error);
        }
        setLoading(false);
    };

    const handleCancelEdit = () => {
        onCancelEdit();
    };

    const modify_article = Trs({ jsonRef: "modify_article" });
    const create_article = Trs({ jsonRef: "create_article" });
    const article_title = Trs({ jsonRef: "article_title" });
    const article_description = Trs({ jsonRef: "article_description" });
    const article_content = Trs({ jsonRef: "article_content" });
    const article_image = Trs({ jsonRef: "article_image" });
    const article_create_it = Trs({ jsonRef: "article_create_it" });
    const article_cancel_modification = Trs({
        jsonRef: "article_cancel_modification",
    });

    return (
        <div className="p-3">
            <ConfirmationOnLeave />
            <Container>
                <Typography
                    variant="h4"
                    align="center"
                    gutterBottom
                    style={{ marginBottom: "20px" }}
                >
                    {editingArticle ? modify_article : create_article}
                </Typography>
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={article_title}
                                name="title"
                                value={formData.title}
                                onChange={handleInputChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={article_description}
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                label={article_content}
                                name="content"
                                value={formData.content}
                                onChange={handleInputChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="image">
                                    {article_image}
                                </InputLabel>
                                <br />
                                <br />
                                <Input
                                    type="file"
                                    id="image"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                margin: "15px 0px",
                            }}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                {editingArticle
                                    ? modify_article
                                    : article_create_it}
                            </Button>
                            {editingArticle && (
                                <Button
                                    onClick={handleCancelEdit}
                                    variant="contained"
                                    color="primary"
                                    style={{ marginLeft: "10px" }}
                                >
                                    {article_cancel_modification}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </form>
                <Loader loading={loading} />
            </Container>
        </div>
    );
};

export default CreateArticleForm;
