import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import apiService from 'service/api';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Trs from 'components/Trs/TrsComponent';
import PasswordValidator from 'password-validator';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Spinner } from 'components/Loader/Spinner';


const passwordSchema = new PasswordValidator();

passwordSchema
    .is().min(8)                                    // min 8
    .is().max(100)                                  // max 100
    .has().uppercase()                              // min 1 uppercase
    .has().lowercase()                              // min 1 lowercase
    .has().digits()                                 // min 1 digit
    .has().symbols();                               // min 1 symbol


function ResetPassword(props) {

    const [passwordCheck, setPasswordCheck] = useState({
        minLength: false,
        maxLength: false,
        hasUppercase: false,
        hasLowercase: false,
        hasDigits: false,
        hasSymbols: false
    });


    const validatePassword = (password) => {
        setPasswordCheck({
            minLength: passwordSchema.is().min(8).validate(password),
            maxLength: passwordSchema.is().max(100).validate(password),
            hasUppercase: passwordSchema.has().uppercase().validate(password),
            hasLowercase: passwordSchema.has().lowercase().validate(password),
            hasDigits: passwordSchema.has().digits().validate(password),
            hasSymbols: passwordSchema.has().symbols().validate(password)
        });
    }


    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const csrfToken = useSelector((state) => state.user.csrfToken);

    const passwords_mismatch_error_text = Trs({ jsonRef: "passwords_mismatch_error_text" });
    const password_requirements_error_text = Trs({ jsonRef: "password_requirements_error_text" });
    const password_reset_success_title = Trs({ jsonRef: "password_reset_success_title" });
    const password_reset_success_text = Trs({ jsonRef: "password_reset_success_text" });
    const password_reset_error_text = Trs({ jsonRef: "password_reset_error_text" });
    const reset_password = Trs({ jsonRef: "reset_password" });
    const forgot_new_password = Trs({ jsonRef: "forgot_new_password" });
    const forgot_password_choose_new = Trs({ jsonRef: "forgot_password_choose_new" });
    const confirm_password = Trs({ jsonRef: "confirm_password" });
    const basic_oops = Trs({ jsonRef: "basic_oops" });

    const submitForm = () => {

        validatePassword(password);

        if (password !== confirmPassword) {
            Swal.fire({
                icon: 'error',
                title: basic_oops,
                text: passwords_mismatch_error_text,
            });
            return;
        }
        if (!passwordSchema.validate(password)) {
            Swal.fire({
                icon: 'error',
                title: basic_oops,
                text: password_requirements_error_text,
            });
            return;
        }
        const formattedData = {
            password
        };

        setIsLoading(true);
        apiService
            .post('accounts/reset-resetpass/' + token + '/', formattedData, csrfToken)
            .then((response) => {
                Swal.fire({
                    icon: 'success',
                    title: password_reset_success_title,
                    text: password_reset_success_text,
                });
                navigate('/auth/login');
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: basic_oops,
                    text: password_reset_error_text,
                });
            }).finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <div className='w-full h-screen'>
            <div className='h-full flex justify-center items-center px-7 py-1'>
                <div className="flex items-center w-[95%] sm:w-[80%] md:w-[60%] lg:w-[40%] h-3/4 sm:h-4/5 md:h-4/5 bg-white rounded-lg shadow-xl">
                    <div className="w-full h-full flex flex-col justify-center items-center">
                        <h1 className="text-2xl text-center font-semibold">{reset_password}</h1>
                        <p className="text-opacity-50 mt-8 text-center text-base px-5 sm:px-8 md:px-10 lg:px-12">
                            {forgot_password_choose_new}
                        </p>
                        <div className="w-full mt-12 flex flex-col justify-center items-center gap-12">
                            <div className="w-3/5 h-full flex flex-col">
                                <label htmlFor="password" className="py-3">
                                    {forgot_new_password}
                                </label>
                                <div className="relative w-full">
                                    <input
                                        type={passwordVisible ? "text" : "password"}
                                        id="password"
                                        className="w-full rounded-lg border border-gray-300 p-2 pr-10" /* Ajout de pr-10 pour laisser de l'espace pour l'icône */
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                    <span
                                        onClick={passwordVisible ? () => setPasswordVisible(false) : () => setPasswordVisible(true)}
                                        className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-xl text-gray-500 bg-white"
                                    >
                                        {passwordVisible ? <FiEyeOff /> : <FiEye />}
                                    </span>
                                </div>
                            </div>
                            <div className="w-3/5 h-full flex flex-col">
                                <label htmlFor="password" className="py-3">
                                    {confirm_password}
                                </label>
                                <div className="relative w-full">
                                    <input
                                        type={confirmPasswordVisible ? "text" : "password"}
                                        id="confirmPassword"
                                        className="w-full rounded-lg border border-gray-300 p-2 pr-10" /* Ajout de pr-10 pour laisser de l'espace pour l'icône */
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        required
                                    />
                                    <span
                                        onClick={confirmPasswordVisible ? () => setConfirmPasswordVisible(false) : () => setConfirmPasswordVisible(true)}
                                        className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-xl text-gray-500 bg-white"
                                    >
                                        {confirmPasswordVisible ? <FiEyeOff /> : <FiEye />}
                                    </span>
                                </div>
                            </div>

                            <div className='w-full flex items-center justify-center'>
                                <button onClick={submitForm} className="w-[45%] mt-5 cursor-pointer p-3 rounded-lg text-white bg-primary font-medium">
                                    {isLoading ? (
                                        <Spinner />
                                    ) : (
                                        <Trs jsonRef="change_password" />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {message && <p className="forgot-password-description text-success">{message}</p>}
        </div>
    )
};

export default ResetPassword;
